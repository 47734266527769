<!-- 故障报修 -->
<template>
    <div class="page_container height_100">
        <div class="bgc_FFFFFF height_100">
            <div class="bgc_E2F0FE border_1_CCDBE5  height_40">
                <div class="box_head1">
                    <span>故障报修</span>
                    <div class="flex align_center fn_size14 mg_left_50">
                        <div>设备编号：</div>
                        <el-input v-model="deviceID" placeholder="请输入设备编号" class="mg_left_8 width_150 "></el-input>
                        <div class="mg_left_20">故障类型：</div>
                        <el-select v-model="ftValue" placeholder="选择故障类型" class="mg_right_10 width_150" size="medium " style="width:120px"
                            clearable>
                            <el-option v-for="item in typeData" :key="item.value" :label="item.description"
                                :value="item.value">
                            </el-option>
                        </el-select>
                        <div class="mg_left_20">故障等级：</div>
                        <el-select v-model="flValue" placeholder="选择故障等级" class="mg_right_10 width_150" clearable style="width:120px"
                            size="medium ">
                            <el-option v-for="(item,index) in levelData" :key="index" :label="item.description"
                                :value="item.value">
                            </el-option>
                        </el-select>
                        <div class="mg_left_20">报修单状态：</div>
                        <el-select v-model="statusValue" placeholder="选择报修状态" class="mg_right_10 width_150" clearable
                            size="medium " style="width:120px">
                            <el-option v-for="(item,index) in statusData" :key="index" :label="item.description"
                                :value="item.value">
                            </el-option>
                        </el-select>
                        <div class="mg_left_20">设备ID：</div>
                        <el-input v-model="dIDValue" size="medium" class="mg_right_10 width_150" style="width:120px" placeholder="输入设备ID">
                        </el-input>
                        <div class="mg_left_20">故障时间：</div>
                        <el-date-picker value-format="timestamp" v-model="DateValue" class="mg_right_10 width_150" type="datetimerange"
                            start-placeholder="开始日期" end-placeholder="结束日期" :picker-options="pickerOptions">
                        </el-date-picker>
                        <el-button type="primary" @click="Querytest"
                            size="medium">查询
                        </el-button>
                    </div>
                </div>
            </div>


            <div class="flex justify_between pdt_20 fn_size16 pdl30 pdr30">
                <div class="flex">
                    <el-button type="primary" icon="el-icon-circle-plus-outline" class="width_140"
                        style=" background: #03CABE;border: none;" @click="gotoAddRepair">添加报修</el-button>
                         <el-button type="primary" class="mg_left_10" @click="exportRepairRecord"
                            style=" background: #03CABE;border: none;">导出</el-button>
                    <!-- <el-button type="primary" icon="el-icon-circle-plus-outline" class="width_140" 
                        style=" background: #FEA313;border: none;" @click="gotoChangeData">修改报修</el-button> -->
                    <!-- <el-button type="primary" icon="el-icon-delete" class="width_140 mg_left_10"
                        style=" background: #F75753;border: none;" @click="deleteData">删除报修</el-button> -->
                </div>
                <!-- <el-button type="primary" class="width_110" style=" background: #009BFD;border: none;">导出
                </el-button> --> 
            </div>
            <div class="pdl30 pdr30" style="height:78%">
                <el-table ref="multipleTable" :data="formInline" tooltip-effect="dark" style="width: 100%"
                    @selection-change="handleSelectionChange" class="mg_top_20" height="100%"  @row-dblclick="dblclickGotoFaultDetails" 
                    :default-sort="{prop: 'date', order: 'descending'}" id="repairPlanTable">

                    <el-table-column type="index" label="序号" width="80">
                    </el-table-column>
                    <el-table-column show-overflow-tooltip prop="failureRptID" label="设备报修编号" width="">
                    </el-table-column>
                    <el-table-column prop="deviceID" label="设备编号">
                    </el-table-column>
                    <el-table-column prop="deviceName" label="设备名称" width="">
                    </el-table-column>
                    <!-- <el-table-column prop="deviceDepartment" label="设备所属部门" width="">
                    </el-table-column> -->
                    
                    <!-- <el-table-column label="是否需要审核" width="80">
                    <template slot-scope="scope">
                        <span>{{scope.row.doAuth}}</span>
                    </template>
                    </el-table-column> -->
                    <!-- <el-table-column label="机器是否停止" width="150">
                        <template slot-scope="scope">
                            <span>{{scope.row.machineStop}}</span>
                        </template>
                    </el-table-column> -->
                    <el-table-column prop="machineStop" label="机器是否停止" :formatter="formatBoolean" width="150">
                    </el-table-column>
                    <el-table-column prop="deviceType_name" label="设备类型">
                    </el-table-column>
                    <el-table-column prop="failureLevel" label="故障等级" width="">
                    </el-table-column>
                    <el-table-column prop="failureType_name" label="故障类型" width="">
                    </el-table-column>
                   
                    <el-table-column prop="oprPerson" label="操作人" width="">
                    </el-table-column>
                    <el-table-column prop="authRoleName" label="审批角色" width="">
                    </el-table-column>
                    <el-table-column prop="failureTime" label="报修时间" width="200" :formatter="formatDate">
                    </el-table-column>
                  
                    <el-table-column prop="remarks" label="备注" show-overflow-tooltip>
                    </el-table-column>
                    <el-table-column prop="orderStatus_name" width="200px" label="故障报修状态">
                    </el-table-column>
                    <el-table-column label="详细">
                        <template slot-scope="scope">
                            <el-button type="text" class="color_009BFD pd_0">
                                <span style="border-bottom: 1px solid #009BFD;"
                                    @click="gotoFaultDetails(scope.row.ID)">详细</span>
                            </el-button>
                        </template>
                    </el-table-column>
    
                    <el-table-column label="操作" width="280">
                        <template slot-scope="scope">
                            <el-button v-if="scope.row.doAuth ==true" type="text" class="color_009BFD pd_0"
                                @click="approvalOperation(scope.row.ID)">
                                <span style="border-bottom: 1px solid #009BFD;">审批</span>
                            </el-button>
                            <el-button type="text" class="color_009BFD pd_0" @click="edit(scope.row)"
                                v-if="scope.row.orderStatus!=1&&scope.row.orderStatus!=2">
                                <span style="border-bottom: 1px solid #009BFD;">修改</span>
                            </el-button>
                            <el-button v-if="scope.row.orderStatus !=1" type="text" class="color_009BFD pd_0"
                                @click="del(scope.row)">
                                <span style="border-bottom: 1px solid #009BFD;">删除</span>
                            </el-button>
                            <div  v-if="scope.row.showGenerateButton">
                                <el-button v-if="scope.row.orderStatus === 1"  type="text" class="color_009BFD pd_0"
                                    @click="gotoCreateCreatePlan(scope.row.ID)">
                                    <span style="border-bottom: 1px solid #009BFD;">生成计划</span>
                                </el-button>
                                <el-button v-if="scope.row.orderStatus === 1 && scope.row.doGenerateOrder == true" type="text" class="color_009BFD pd_0"
                                    @click="gotoCreateWorkOrder(scope.row.ID)">
                                    <span style="border-bottom: 1px solid #009BFD;">生成工单</span>
                                </el-button>
                            </div>
                        </template>
                    </el-table-column>
                </el-table>
                <div class="flex justify_end">
                    <el-pagination layout="prev, pager, next" :total="pageQuantity*pageSize" :page-size="pageSize"
                        :current-page.sync="currentPage" @current-change="handleCurrentChange"></el-pagination>
                </div>
            </div>
            <el-dialog :visible.sync="dialogFormVisible" width="30%">
                <div slot="title" class="fn_size18 color_000000 flex justify_center font_bold">审批</div>
                <el-form :model="form" class="pdl30 pdr30 mg_top_24">
                    <el-form-item label="审批结果：" :label-width="formLabelWidth">
                        <el-radio-group v-model="form.authResult" class="">
                            <el-radio v-for="item in approvalList" :key="item.value" :label="item.value">
                                {{item.description}}
                            </el-radio>
                        </el-radio-group>
                    </el-form-item>
                </el-form>
                <div slot="footer" class="flex justify_center pdb_20">
                    <el-button @click="dialogFormVisible = false">取 消</el-button>
                    <el-button type="primary" @click="confirmAuth">确 定</el-button>
                    <!-- -->
                </div>
            </el-dialog>


        </div>
    </div>
</template>

<script>
    import {
        failureReportQuery,
        failureReportMultiAuthQueryLimit,
        failureReportAuthorize,
        multiAuthorizeResultQuery,
        faultTypeQuery,
        faultLevelQuery,
        failureReportDelete,
        repairPlanGeneration,
        repairOrderGeneration,
        failureStatusQuery
    } from '@/api/api_deviceManagement.js'
    export default {
        data() {
            return {
                statusValue:null,
                statusData:[],
                tabIndex: 0,
                multipleSelection: [],
                //所有数据
                formInline: [],
                DateValue: null,
                ftValue: "",
                flValue: "",
                dIDValue: '',
                ID: '',
                userID: "",
                deleteNum: [],
                //下拉框数据
                //等级下拉框
                levelData: [],
                //类型下拉框
                typeData: [],
                formLabelWidth: '120px',
                //页码
                currentPage: 1,
                pageSize: 10,
                pageQuantity: 0,
                aaa: [],
                valList: [],
                approvalList: [],
                dialogFormVisible: false,
                form: {
                    authResult:""
                },
                deviceID:''
            }
        },

        mounted() {
            if (localStorage.getItem('userInfo') != null) {
                let userInfo = JSON.parse(localStorage.getItem('userInfo'))
                this.userID = userInfo.userID;
            }
            // this.failureReportQuery();
            this.failureReportMultiAuthQueryLimit()
            this.faultTypeQuery();
            this.faultLevelQuery();
            this.failureStatusQuery();

        },
        methods: {
              //导出数据
          exportRepairRecord() {
                this.$exportExcel.exportDataTwo('repairPlanTable', '故障报修表')
            },
               //详细
            dblclickGotoFaultDetails(row, column, event) {
                localStorage.setItem('oderId', row.ID);
                // this.$router.push({
                //     path: '/deviceManagement/maintenanceManagement/repairPlan/repairPlanDetails',
                //     // query: {
                //     //     id: id
                //     // },
                //     // query:{setid:111222}
                //     // params:{setid:111222}//地址栏中看不到参数的内容
                // })
            },

            //查询按钮
            Querytest() {
                this.currentPage = 1;
                this.failureReportMultiAuthQueryLimit()
            },

            //选中的行
            handleSelectionChange(val) {
                this.multipleSelection = val;
                //要批量删除的数组
                // this.deleteNum = []; //数组赋值前，需要清空一下数组
                this.deleteNum = "";
                // this.isAuth = '';
                for (var i = 0; i < val.length; i++) {
                    this.deleteNum += val[i].ID + ",";
                    // this.isAuth += val[i].userID + ",";
                }
                //去掉最后一个逗号
                if (this.deleteNum.length > 0) {
                    this.deleteNum = this.deleteNum.substr(0, this.deleteNum.length - 1);
                }

                var delNum = this.deleteNum;
                window.localStorage.setItem('delNum', this.deleteNum)
                // var isAuth =this.isAuth;
                // window.localStorage.setItem('isAuth')
            },
            handleChange(id) {
                this.tabIndex = id;
            },
            gotoCreateCreatePlan(val) {
                this.ID = val
                this.repairPlanGeneration()
                this.failureReportMultiAuthQueryLimit()
                // this.$router.push({
                //     path: '/deviceManagement/maintenanceManagement/troubleShooting/createPlan',
                //     // query:{setid:111222}
                //     // params:{setid:111222}//地址栏中看不到参数的内容
                // })
            },
            gotoCreateWorkOrder(val) {
                this.ID = val
                this.repairOrderGeneration()
                this.failureReportMultiAuthQueryLimit()
                // this.$router.push({
                //     path: '/deviceManagement/maintenanceManagement/troubleShooting/createWorkOrder',
                //     // query:{setid:111222}
                //     // params:{setid:111222}//地址栏中看不到参数的内容
                // })
            },
            gotoToExamine() {
                //  if(this.multipleSelection.length > 1){
                //      this.$message({
                //         message: '您只能选择一条数据',
                //         type: 'warning'
                //     });
                //     this.multipleSelection=[]
                // }else if(this.multipleSelection.length=0){
                //     this.$message({
                //         message: '请选择一条数据',
                //         type: 'warning'
                //     });
                // }else{
                //     this.dialogFormVisible = ture

                // }

                // this.$router.push({
                //     path: '/deviceManagement/maintenanceManagement/troubleShooting/toExamine',
                //     query:{}
                // })
            },
            gotoFaultDetails(val) {
                localStorage.setItem('select', val)
                this.$router.push({
                    path: '/deviceManagement/maintenanceManagement/troubleShooting/faultDetails',
                })
            },
            gotoAddRepair() {
                this.$router.push({
                    path: '/deviceManagement/maintenanceManagement/troubleShooting/addRepair',
                })
            },
            //分页数据
            async failureReportMultiAuthQueryLimit() {
                var param = {
                    userID: this.userID,
                    pageSize: this.pageSize,
                    pageNumber: this.currentPage,
                }
                if(this.deviceID){
                    param.deviceID = this.deviceID;
                }
                // this.maintainCategoryValue != '' && this.maintainCategoryValue != null
                if (this.dIDValue != '' && this.dIDValue != null) {
                    param.deviceID = this.dIDValue
                }
                if (this.DateValue != null) {
                    param.failureStartTime = this.DateValue[0];
                    param.failureEndTime = this.DateValue[1];
                }
                if (this.flValue != '' && this.flValue != null) {
                    param.failureLevel = this.flValue
                }
                if (this.ftValue != '' &&this.ftValue != null) {
                    param.failureType = this.ftValue
                }
                if (this.statusValue != null && this.statusValue !== "") {
                    param.orderStatus = this.statusValue
                }
                const selectRes = await failureReportMultiAuthQueryLimit(param);
                this.formInline = selectRes.array;
                this.pageQuantity = selectRes.pageQuantity;
                console.log(this.formInline);
            },
            //所有数据
            async failureReportQuery() {
                var param = {
                    // ID:1,
                    // failureRptID:'1',
                    // deviceID: this.dIDValue,
                    // deviceName:'1',
                    // deviceType:'1',
                    // deviceModel:'1',
                    // deviceDepartment:'1',
                    // deviceLocation:'1',
                    // failureStartTime: this.DateValue[0],
                    // failureEndTime: this.DateValue[1],
                    // oprStartTime:this.currentDate[0],
                    // oprEndTime:this.currentDate[1],
                    // oprPerson:'1',
                    // oprPhoneNumber:'1',
                    // failureLevel: this.flValue,
                    // failureType: this.ftValue,
                    // authSort:'1',
                    // machineStop:false,
                    // failureDesc:'1',
                    // orderStatus:1,
                    // remarks:'',
                    // userID:'user_zzc',
                }

                if (this.repairTimeValue != null) {
                    param.failureStartTime = this.DateValue[0];
                    param.failureEndTime = this.DateValue[1];
                }
                if (this.flValue) {
                    param.failureLevel = this.flValue
                }
                if (this.ftValue) {
                    param.failureType = this.ftValue
                }
                const selectRes = await failureReportQuery(param);
                this.formInline = selectRes.rows;
                // console.log(this.formInline);
            },
            //审批
            approvalOperation(id) {
                this.form = {};
                this.form.ID = id;
                // console.log(id)
                this.dialogFormVisible = true;
                this.multiAuthorizeResultQuery();
            },
            async multiAuthorizeResultQuery() {
                const selectRes = await multiAuthorizeResultQuery();
                this.approvalList = selectRes.array;
            },
            //审批
            async confirmAuth() {
            //   if(this.form.authResult == undefined){
            //        this.$message.error('请选择审批是否通过!');  
                     
            //  }else{
                    this.form.userID = this.userID; //登录获取
                    const selectRes = await failureReportAuthorize(this.form);
                    
                    if (selectRes.data.result) {
                        this.$message({
                            message: selectRes.message,
                            type: 'success'
                        });
                        this.dialogFormVisible = false;
                        this.form = {};
                        this.failureReportMultiAuthQueryLimit();
                    } else {
                        this.$message.error(selectRes.message);
                    }

            // }

            },
            //状态下拉框
            async failureStatusQuery() {
                const selectRes = await failureStatusQuery()
                this.statusData = selectRes.data
            },
            //类型下拉框
            async faultTypeQuery() {
                var param = {
                    userID: this.userID
                }
                const selectRes = await faultTypeQuery(param)
                this.typeData = selectRes.data

            },
            //等级下拉框
            async faultLevelQuery() {
                var param = {
                    userID: this.userID
                }
                const selectRes = await faultLevelQuery(param)
                this.levelData = selectRes.data
            },

            //删除
            async failureReportDelete(id) {
                var param = {
                    ID: id
                }
                const selectRes = await failureReportDelete(param)
                this.deleteResult = selectRes.data.result
                if (selectRes.data.result) {
                    this.$message({
                        message: selectRes.message,
                        type: 'success'
                    })
                    this.failureReportMultiAuthQueryLimit()
                } else {
                    this.$message({
                        message: selectRes.message,
                        type: 'warning'
                    })
                    this.failureReportMultiAuthQueryLimit()
                }


            },
            //根据showGenerateButton参数来决定最终是否展示，true展示，false隐藏
            showGenerateButton(){
                if(repairPlanGeneration==true){

                }else{
                    
                }
            },
            //生成计划
            async repairPlanGeneration() {
                var param = {
                    ID: this.ID,
                    userId: this.userID
                }
                const selectRes = await repairPlanGeneration(param)
                if (selectRes.data.result == true) {
                    this.$message({
                        message: selectRes.message,
                        type: 'success'
                    })
                } else {
                    this.$message({
                        message: selectRes.message,
                        type: 'warning'
                    })
                }
            },
            //生成工单
            async repairOrderGeneration() {
                var param = {
                    ID: this.ID
                }
                const selectRes = await repairOrderGeneration(param)
                if (selectRes.data.result == true) {
                    this.$message({
                        message: selectRes.message,
                        type: 'success'
                    })
                } else {
                    this.$message({
                        message: selectRes.message,
                        type: 'warning'
                    })
                }
            },
            del(row) {
                this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.currentPage = this.delData.delData(this.formInline.length, this.pageQuantity, this
                        .currentPage)
                    this.failureReportDelete(row.ID)
                }).catch(() => {
                    // this.$message({
                    //     type: 'info',
                    //     message: '已取消删除'
                    // });
                });

            },
            edit(row) {
                console.log("点击行数据", row);
                var editValue = row.ID;
                window.localStorage.setItem('editValue', row.ID);

                this.$router.push({
                    path: '/deviceManagement/maintenanceManagement/troubleShooting/changeData',
                    // name:'/deviceManagement/maintenanceManagement/troubleShooting/changeData',
                    // query: {ID:this.deleteNum}
                })


            },
            handleCurrentChange(val) {
                this.PageNumber = val;
                this.failureReportMultiAuthQueryLimit(); //查询table数据的方法
            },
            formatDate(row, column) {
                // 获取单元格数据
                let data = row[column.property]
                if (data == undefined) {
                    return ''
                };
                return this.tools.formatDate(data, 'yyyy-MM-dd hh:mm:ss');
            },
            formatBoolean(row, column) {
                // 获取单元格数据
                let data = row[column.property]
                if (data == true) {
                    return '是'
                }
                if (data == false) {
                    return '否'
                };
               
            },

        }
    }
</script>

<style scoped>



</style>
<style>
    /* .btn_hover:hover {
        background-color: #03CABE;
        opacity: 0.5;
        color: #FFFFFF;
    } */

    /* .el-button:focus,
    .el-button:hover {
    } */
</style>